import * as Yup from 'yup';
import { formErrorMessages } from 'api/resources/responseMessages/formErrors';
import { Ids } from './types';
import { SelectOption } from 'components/Select';

export const schemeCodeOverride_access: (number | undefined)[] = [
  Ids.Allianz,
  Ids.LV,
  Ids.Caps_IMS,
];
export const deployToSiteCode_access: (number | undefined)[] = [Ids.Covea];
export const deploymentType_access: (number | undefined)[] = [Ids.Covea];
export const coveaCode_access: (number | undefined)[] = [Ids.Covea];
export const sysType_access: (number | undefined)[] = [Ids.DLG2];
export const externalRepairerName_access: (number | undefined)[] = [Ids.LV];
export const kindertons_access: (number | undefined)[] = [Ids.Kindertons];
export const fixSiteCode_access: (number | undefined)[] = [Ids.FixCloud];
export const fixType_access: (number | undefined)[] = [Ids.FixCloud];
export const overwriteExisting_access: (number | undefined)[] = [Ids.ITAS_V2];
export const customSetting_access: (number | undefined)[] = [Ids.ITAS_V2];
export const manufacturer_access: (number | undefined)[] = [Ids.ITAS_V2];

export const sysType_narg: (string | null) = 'NARG';
export const capsLinkExcluded: (string | null) = 'National ARG';
export const CAPS_V3:  (string | null) = 'CAPSv3';
export const CAPS_V5:  (string | null) = 'CAPSv5';

export const validation = Yup.object({
  workProvider: Yup.object({
    id: Yup.number(),
    text: Yup.string(),
    value: Yup.string(),
  }).required(formErrorMessages.required),
  ticketId: Yup.string().required(formErrorMessages.required),
  serviceStartDate: Yup.string().nullable().required(formErrorMessages.required),
  comment: Yup.string().required(formErrorMessages.required),

  externalCode: Yup.string()
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => WP?.optionalParam?.requireExternalCode,
      then: Yup.string().nullable().required(formErrorMessages.required),
    }),
  schemeCodeOverride: Yup.string()
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => schemeCodeOverride_access.includes(WP?.id),
      then: Yup.string().nullable().required(formErrorMessages.required),
    }),
  deployToSiteCode: Yup.string()
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => deployToSiteCode_access.includes(WP?.id),
      then: Yup.string().nullable().required(formErrorMessages.required),
    }),
  deploymentType: Yup.object({
    id: Yup.number(),
    text: Yup.string(),
    value: Yup.string(),
  })
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => deploymentType_access.includes(WP?.id),
      then: Yup.object({
        id: Yup.number().required(formErrorMessages.required),
        text: Yup.string().required(formErrorMessages.required),
        value: Yup.string().required(formErrorMessages.required),
      })
        .nullable()
        .required(formErrorMessages.required),
    }),
  coveaCode: Yup.string()
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => coveaCode_access.includes(WP?.id),
      then: Yup.string().nullable().required(formErrorMessages.required),
    }),
  sysType: Yup.object({
    id: Yup.number(),
    text: Yup.string(),
    value: Yup.string(),
  })
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => sysType_access.includes(WP?.id),
      then: Yup.object({
        id: Yup.number().required(formErrorMessages.required),
        text: Yup.string().required(formErrorMessages.required),
        value: Yup.string().required(formErrorMessages.required),
      })
        .nullable()
        .required(formErrorMessages.required),
    }),
  externalRepairerName: Yup.string().nullable(),
  kindertonsCode: Yup.string()
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => kindertons_access.includes(WP?.id),
      then: Yup.string().nullable().required(formErrorMessages.required),
    }),
  fixSiteCode: Yup.string()
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => fixSiteCode_access.includes(WP?.id),
      then: Yup.string().nullable().required(formErrorMessages.required),
    }),
  fixType: Yup.object({
    id: Yup.number(),
    text: Yup.string(),
    value: Yup.string(),
  })
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => fixType_access.includes(WP?.id),
      then: Yup.object({
        id: Yup.number().required(formErrorMessages.required),
        text: Yup.string().required(formErrorMessages.required),
        value: Yup.string().required(formErrorMessages.required),
      })
        .nullable()
        .required(formErrorMessages.required),
    }),
  customSettings: Yup.string()
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => customSetting_access.includes(WP?.id),
      then: Yup.string().nullable().required(formErrorMessages.required),
    }),
  manufacturer: Yup.string()
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => manufacturer_access.includes(WP?.id),
      then: Yup.string().nullable().required(formErrorMessages.required),
    }),
  overwriteExisting: Yup.boolean()
    .nullable()
    .when('workProvider', {
      is: (WP: SelectOption) => overwriteExisting_access.includes(WP?.id),
      then: Yup.boolean().nullable(),
    }),
});

export const selectOptionsDeploymentType = [
  {
    id: 5,
    value: 5,
    text: 'Advance',
  },
  {
    id: 9,
    value: 9,
    text: 'CAPS',
  },
  {
    id: 6,
    value: 6,
    text: 'Voyager',
  },
];
export const selectOptionsSysType = [
  {
    id: 0,
    value: 'STD',
    text: 'STD',
  },
  {
    id: 1,
    value: 'CAPS',
    text: 'CAPS',
  },
  {
    id: 2,
    value: 'NARG',
    text: 'NARG',
  },
];
export const selectOptionsType = [
  {
    id: 0,
    value: 'Franchisee',
    text: 'Franchisee',
  },
  {
    id: 1,
    value: 'SubCon',
    text: 'SubCon',
  },
  {
    id: 2,
    value: 'External',
    text: 'External',
  },
];

export const schemeCodeOverrideOptions = [
  {
    id: Ids.Allianz,
    options: [
      { id: 1, value: '', text: 'No override' },
      { id: 2, value: 'AVANT_COMM', text: 'AVANT_COMM' },
      { id: 3, value: 'AVANT_BS10125', text: 'AVANT_BS10125' },
    ],
  },
  {
    id: Ids.LV,
    options: [
      { id: 1, value: 'LV', text: 'LV' },
      { id: 2, value: 'AVANT_LVMH', text: 'AVANT_LVMH' },
    ],
  },
  {
    id: Ids.Caps_IMS,
    options: [
      { id: 1, value: 'IMS', text: 'IMS' },
      { id: 2, value: 'IMSENT', text: 'IMSENT' },
    ],
  },
];

export const selectOptionsManufacturer = [
  {
    id: 1,
    value: 'Audi',
    text: 'Audi',
  },
  {
    id: 2,
    value: 'Bentley',
    text: 'Bentley',
  },
  {
    id: 3,
    value: 'Citroën',
    text: 'Citroën',
  },
  {
    id: 4,
    value: 'Honda',
    text: 'Honda',
  },
  {
    id: 5,
    value: 'Jaguar',
    text: 'Jaguar',
  },
  {
    id: 6,
    value: 'Land Rover',
    text: 'Land Rover',
  },
  {
    id: 7,
    value: 'Mazda',
    text: 'Mazda',
  },
  {
    id: 8,
    value: 'Mercedes-Benz',
    text: 'Mercedes-Benz',
  },
  {
    id: 9,
    value: 'Peugeot',
    text: 'Peugeot',
  },
  {
    id: 10,
    value: 'Porsche',
    text: 'Porsche',
  },
  {
    id: 11,
    value: 'Seat',
    text: 'Seat',
  },
  {
    id: 12,
    value: 'Skoda',
    text: 'Skoda',
  },
  {
    id: 13,
    value: 'Toyota',
    text: 'Toyota',
  },
  {
    id: 14,
    value: 'Vauxhall',
    text: 'Vauxhall',
  },
  {
    id: 15,
    value: 'VW',
    text: 'VW',
  },
];
