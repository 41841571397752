import styled from '@emotion/styled/macro';
import { Paper, FilterOptionsState } from '@mui/material';
import Autocomplete, {
  AutocompleteInputChangeReason,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@mui/material/Autocomplete';
import React, { Dispatch, useRef, FocusEventHandler, ReactNode } from 'react';
import { AutoSizer as _AutoSizer, AutoSizerProps } from 'react-virtualized';
import { FixedSizeList as List } from 'react-window';

export interface SelectOption<T = string | number> {
  id: number;
  value?: T;
  text: string;
}

interface Props<T> {
  defaultValue?: SelectOption<T>;
  options: SelectOption<T>[];
  value?: SelectOption<T>;
  onChange?:
    | Dispatch<React.SetStateAction<SelectOption<T> | null>>
    | ((option: SelectOption<T> | null) => void);
  onInputChange?: (
    event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  inputValue?: string;
  renderInput: (params: AutocompleteRenderInputParams) => React.ReactNode;
  renderOption?: (
    props: unknown,
    option: SelectOption<T>,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  open?: boolean;
  getOptionDisabled?: (option: SelectOption<T>) => boolean;
  className?: string;
  onOpen?: (event: React.ChangeEvent<unknown>) => void;
  onClose?: (event: React.ChangeEvent<unknown>) => void;
  size?: 'small' | 'medium';
  onBlur?: FocusEventHandler;
  onFocus?: () => void;
  groupBy?: (option: SelectOption<T>) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => ReactNode;
  filterOptions?: (
    options: SelectOption<T>[],
    state: FilterOptionsState<SelectOption<T>>
  ) => SelectOption<T>[];
  disabled?: boolean;
  existingOptionsList?: number[];
  existingOptionColor?: string;
}

export default function InputSelectWithSearch<T>({
  options,
  value,
  defaultValue,
  onChange,
  inputValue,
  onInputChange,
  renderInput,
  open,
  getOptionDisabled,
  className,
  onOpen,
  onClose,
  size = 'medium',
  onBlur,
  onFocus,
  groupBy,
  renderGroup,
  filterOptions,
  disabled,
  existingOptionColor,
  existingOptionsList,
}: Props<T>) {
  const listRef = useRef<List>(null);
  const AutoSizer = _AutoSizer as unknown as React.FC<AutoSizerProps>;

  const ListboxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
  >(function ListboxComponent(props, ref) {
    const { children, ...other } = props;

    const itemData = React.Children.toArray(children).filter(
      React.isValidElement
    );

    const itemCount = itemData.length;

    return (
      <div ref={ref} {...other}>
        <AutoSizer>
          {({ width, height }) => (
            <List
              ref={listRef}
              height={height}
              width={width}
              itemSize={30}
              itemCount={itemCount}
              itemData={itemData}
              overscanCount={5}
            >
              {({ index, style, data }) => {
                return React.cloneElement(data[index] as React.ReactElement, {
                  style: {
                    ...style,
                    top: style.top,
                  },
                });
              }}
            </List>
          )}
        </AutoSizer>
      </div>
    );
  });

  return (
    <Container open={open}>
      <Autocomplete
        disablePortal
        disableClearable
        disableCloseOnSelect
        disabled={disabled}
        size={size}
        fullWidth
        ListboxComponent={ListboxComponent}
        id="repairers-box"
        forcePopupIcon={false}
        defaultValue={defaultValue}
        options={options}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        isOptionEqualToValue={(option, e) => option.id === e.id}
        onChange={(_event, newValue) => {
          if (onChange) onChange(newValue);
        }}
        PaperComponent={({ children }) => <StyledPaper>{children}</StyledPaper>}
        inputValue={inputValue}
        onInputChange={(event, newInputValue, reason) =>
          onInputChange && event && onInputChange(event, newInputValue, reason)
        }
        getOptionLabel={(option) => option.text}
        getOptionDisabled={getOptionDisabled}
        renderInput={renderInput}
        renderOption={(props, option) => {
          const isSelected = existingOptionsList?.includes(option.id);
          const color = isSelected ? existingOptionColor : undefined;
          return (
            <li {...props} style={{ backgroundColor: color }}>
              {option.text}
            </li>
          );
        }}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        className={className}
        groupBy={groupBy}
        renderGroup={renderGroup}
        filterOptions={filterOptions}
      />
    </Container>
  );
}

const Container = styled.div<{ open?: boolean }>`
  width: 100%;
  svg {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.palette.secondary.main};
    visibility: visible;
  }

  fieldset {
    border: ${({ open }) => (open ? '0px' : 'inherits')};
  }

  & .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0px 30px 0px 5px;
    font-size: ${({ theme }) => theme.fontSize.s};
    border-bottom-left-radius: ${({ open }) => (open ? 0 : 'inherits')};
    border-bottom-right-radius: ${({ open }) => (open ? 0 : 'inherits')};
    box-shadow: ${({ theme, open }) => (open ? theme.shadow.m : 'inherits')};
    border: ${({ open }) => (open ? '0px' : 'inherits')};
    border-bottom: ${({ theme, open }) =>
      open ? `1px solid ${theme.palette.secondary.active}` : 'inherits'};
  }

  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${({ open }) => (open ? '0px !important' : 'inherits')};
  }

  & .MuiAutocomplete-listbox {
    min-height: 55vh;
  }
`;

const StyledPaper = styled(Paper)`
  margin-top: 0px;
  box-shadow: ${({ theme }) => theme.shadow.m};
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  font-size: ${({ theme }) => theme.fontSize.s};
  & .expand-container {
    display: flex;
    justify-content: flex-end;
  }
  & .icon-button {
    position: absolute;
    bottom: -30px;
    right: 0;
  }
`;
