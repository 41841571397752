import { PrimaryDatePicker, PrimaryDateTimePicker } from './DatePicker';

interface Props {
  name?: string;
  onChange: (date: Date | null) => void;
  value: Date | null;
  isDisabled?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  onBlur?(): void;
}

export function DatePickerField({
  name,
  value,
  minDate,
  maxDate,
  onChange,
  onBlur,
  isDisabled = false,
}: Props) {
  return (
    <PrimaryDatePicker
      name={name}
      selected={value}
      minDate={minDate}
      maxDate={maxDate}
      disabled={isDisabled}
      onChange={(date) => onChange(date)}
      onBlur={onBlur}
    />
  );
}

export function DateTimePickerField({
  name,
  value,
  minDate,
  maxDate,
  onChange,
  isDisabled = false,
}: Props) {
  return (
    <PrimaryDateTimePicker
      name={name}
      selected={value}
      minDate={minDate}
      maxDate={maxDate}
      disabled={isDisabled}
      onChange={(date) => onChange(date)}
    />
  );
}
