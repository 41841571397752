import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RepairerDetailsParams } from 'core/routes';
import {
  MainPageContainer,
  pagePaddingLeft,
  UpperInfoContainer,
} from 'components/PageLayout';
import { Heading } from 'components/Typography';
import { Container, GridItem } from 'components/Layout';
import { BackButton } from 'components/Button';
import styled from '@emotion/styled';
import { errorMessages } from 'api/resources/responseMessages/failure';
import { AddConnectionForm } from './AddConnection';
import { AxiosError } from 'axios';
import {
  useAddConnection,
  useAvailableWorkProviders,
  useRepairerWorkProviders,
} from 'pages/hooks';
import { AdminAddRepairerConnectionModel } from 'api/resources/models/AutoGenerated';
import PrimaryToast from 'components/PrimaryToast';
import { successMessages } from 'api/resources/responseMessages/success';
import { getBadRequestErrorMessage } from 'api';
import { TitleInfo } from 'components/TitleInfo';
import { AddConnectionActionPopup } from 'components/AddConnectionActionPopup';
import { guidanceSaveAddConnection } from 'api/resources/responseMessages/infoMessages';
import { capsLinkExcluded } from './constants';


const useRepairerDetailsParams = () => {
  const { repairerId: rawRepairerId, siteCode: rawSiteCode } =
    useParams<RepairerDetailsParams>();
  const repairerId = Number(rawRepairerId);
  const siteCode = rawSiteCode || '';
  if (isNaN(repairerId) && !siteCode)
    throw new Error(errorMessages.EditInvalidId('RepairerId or Sitecode'));
  return { repairerId, siteCode };
};

export const AddConnection = () => {
  const navigate = useNavigate();
  const { repairerId, siteCode } = useRepairerDetailsParams();

  const { isWorkProviderLoading, workProviders } = useRepairerWorkProviders();
  const { availableWorkProviders, isAvailableWorkProviderLoading } =
    useAvailableWorkProviders(siteCode);
  const { connectionAdd, isAdding } = useAddConnection(repairerId, siteCode);

  const [toastState, setToastState] = useState<{
    message: string;
    isOpen: boolean;
    severity: 'error' | 'success';
  }>({
    message: '',
    isOpen: false,
    severity: 'success',
  });
  const [isConnectionAdded, setIsConnectionAdded] = useState(false);

  const isBusy =
    isAdding || isWorkProviderLoading || isAvailableWorkProviderLoading;

  const [actionPopup, setActionPopup] = useState<{
    title: string;
    guidance: string | null;
    isOpen: boolean;
    onSubmit?: () => void;
  }>({
    title: '',
    guidance: '',
    isOpen: false,
  });
  
  return (
    <React.Fragment>
      <CustomMainPageContainer direction="column" wrap="nowrap">
        <UpperInfoContainer direction="column">
          <BackButton text="Back to Repairers Details" />
          <Heading>
            <div className="title">
              Add New Connection{' '}
              <TitleInfo
                message="Connections can be created from this location. If a connection for a Work Provider already exists, it will be displayed in Green. Re-running the connection will not duplicate any information but will add any new amendments to the associated connection. Please ensure you approve any associated CAPS entries."
                title="Add New Connection"
              />
            </div>
          </Heading>
        </UpperInfoContainer>

        <CustomContainer direction="column">
          <Container direction="row">
            <GridItem md={12} sm={12} xs={12}>
              <AddConnectionForm
                isBusy={isBusy}
                siteCode={siteCode}
                onSubmit={(data) =>
                  {data.isExistingConnection ? 
                    setActionPopup({
                      isOpen: true,
                      title: 'Details',
                      guidance: guidanceSaveAddConnection,
                      onSubmit: () => 
                        onSubmit({
                          ...data,     
                    })
                  }) :
                    onSubmit(data)
                  }
                }
                repairerId={repairerId}
                workProviders={workProviders}
                existingWorkProviders={availableWorkProviders}
              />
            </GridItem>
          </Container>
        </CustomContainer>
      </CustomMainPageContainer>
      <PrimaryToast
        message={toastState.message}
        isOpen={toastState.isOpen}
        onClose={() => {
          setToastState({ ...toastState, isOpen: false });
          isConnectionAdded && navigate(-1);
        }}
        severity={toastState.severity}
      />
      <AddConnectionActionPopup
        title={actionPopup.title}
        isOpen={actionPopup.isOpen}
        guidance={actionPopup.guidance}
        onSubmit={actionPopup.onSubmit}
        onClose={() =>
          setActionPopup({
            title: '',
            guidance: '',
            isOpen: false,
          })
        }
      />
    </React.Fragment>
  );

  function onSubmit(data: AdminAddRepairerConnectionModel) {
    if (!isConnectionAdded)
      connectionAdd(data)
        .then(() => {
          setIsConnectionAdded(true);
          setToastState({
            message: data.isExistingConnection ? successMessages.updateConnection : successMessages.addConnection,
            isOpen: true,
            severity: 'success',
          });
        })
        .catch((error: AxiosError) => handleError(error));
  }

  interface ErrorResponseData {
    error: string | null;
    isSuccess: number;
  }

  function handleError(error: AxiosError) {
    const errorData = getBadRequestErrorMessage(error);
    const errorMessage = (errorData as ErrorResponseData)?.error;
    setToastState({
      message: errorMessage || errorMessages.addConnection,
      isOpen: true, 
      severity: 'error', 
    });
  }
};

const CustomMainPageContainer = styled(MainPageContainer)`
  @media (min-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding-right: 0;
  }
  & .title {
    display: flex;
  }
`;

const CustomContainer = styled(Container)`
  background: ${({ theme }) => theme.palette.background.default};
  padding-left: ${({ theme }) => pagePaddingLeft(theme)};
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.md}px`}) {
    padding: 0 20px;
  }
  @media (max-width: ${({ theme }) => `${theme.breakpoints.values.sm}px`}) {
    padding: 0 15px;
  }
`;
