export const successMessages = {
  createRepairerDetail: 'Repairer successfully created',
  updateRepairerDetail: 'Repairer successfully updated',
  lockRepairer: 'Repairer successfully locked',
  unlockRepairer: 'Repairer successfully unlocked',
  terminateRepairer: 'Repairer successfully terminated',
  addConnection: 'Connection successfully Added',
  updateConnection: 'Connection successfully updated',
  addFNOL: 'FNOL Configuration successfully Added',
  createMaintenanceMessage: 'Maintenance Message successfully created',
  editMaintenanceMessage: 'Maintenance Message successfully updated',
  deleteMaintenanceMessage: 'Maintenance Message successfully deleted',
  reportStatusJobUpdate: 'Job Status Successfully updated',
  reportWPNameChange: 'Work Provider Name Successfully updated',
  deleteConnection: 'Connection successfully deleted',
  reactivateRepairer: 'Repairer successfully reactivated',
};
